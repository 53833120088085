import React from 'react';
import '../../../App.css';
import {
    Grid,
    Typography,
    InputLabel
} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import SelectField from "../../../components/FormFields/SelectField";
import {useFormikContext} from "formik";


const useStyles = makeStyles((theme) => ({
    title: {
        textAlign: 'center',
        margin: '0.25em',
        fontSize: '1.75em',
        fontWeight: 'bold'
    },
    input: {
        padding: '1em !important'
    },
    form: {
        width: '80%',
        margin: 'auto',
        borderRadius: '1em',
        boxShadow: '1px 1px 12px #555'
    },
    sectionTitle: {
        backgroundColor: '#EF9700',
        borderRadius: '1em 1em 0 0',
        color: 'white',
        width: '100%'
    },
    text: {
        textAlign: 'justify',
        fontSize: '0.90em',
        margin: '1em'
    },
    innerinput: {
        width: '100%'
    },
}));
const geolocationVal = [
    {
        value: '0',
        label: 'Non'
    },
    {
        value: '1',
        label: 'Oui'
    }
];



const Geolocalisation = (props) => {

    const classes = useStyles();
    const {values, submitForm} = useFormikContext();
    const {
        formField: {
            Geoloc
        }
    } = props;

    const getPosition = (position) => {
        values.X = position.coords.longitude;
        values.Y = position.coords.latitude;
    };

    if (values.Geoloc === "1" && (!values.X || !values.Y)) {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(getPosition);
        }
    }

    return (
        <Grid container spacing={1} direction="column" className={classes.form}>
            <Grid className={classes.sectionTitle} item xs={12}>
                <Typography className={classes.title} component="h3" variant="h5">
                    Lieu du dommage
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography className={classes.text} component="h4" variant="h6">
                    Souhaitez-vous activer la géolocalisation afin d’identifier rapidement le lieu du dommage ?
                </Typography>
            </Grid>
            <Grid className={classes.input} item xs={12}>
                <SelectField
                    className={classes.innerinput}
                    name={Geoloc.name}
                    label={Geoloc.label}
                    data={geolocationVal}
                />
            </Grid>
        </Grid>
    );
};

export default Geolocalisation;
