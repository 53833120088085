import axios from 'axios';

let PORT = null;
let HOST = null;
let baseURL = null;

if (window.location.host.indexOf("localhost")>=0) {
    PORT = 8000;
    HOST = 'http://localhost';
    baseURL = `${HOST}:${PORT}`;
} else {
    HOST = '/api/public';
    baseURL = `${HOST}`;
}

const apiConfig = {
    baseURL: baseURL,
};

export default axios.create({
    // `url` is the server URL that will be used for the request
    method: 'POST',
    ...apiConfig,
});