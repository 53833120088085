import React from 'react';
import '../../App.css';
import {
    Grid,
    Typography,
} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import {useFormikContext} from "formik";


const useStyles = makeStyles((theme) => ({
    title: {
        textAlign: 'center',
        margin: '0.25em',
        fontSize: '1.75em',
        fontWeight: 'bold'
    },
    input: {
        padding: '1em !important'
    },
    form: {
        width: '80%',
        margin: 'auto',
        borderRadius: '1em',
        boxShadow: '1px 1px 12px #555'
    },
    sectionTitle: {
        backgroundColor: '#EF9700',
        borderRadius: '1em 1em 0 0',
        color: 'white',
        width: '100%'
    },
    text: {
        textAlign: 'justify',
        fontSize: '0.90em',
        margin: '1em'
    },
    innerinput: {
        width: '100%'
    },
}));

const Resume = () => {

    const classes = useStyles();
    const {values, submitForm} = useFormikContext();

    return (
        <Grid container spacing={1} direction="column" className={classes.form} alignItems="center">
            <Grid className={classes.sectionTitle} item xs={12}>
                <Typography className={classes.title} component="h3" variant="h5">
                    Merci d'avoir signalé ce dommage réseau.
                </Typography>
            </Grid>
            <Grid item xs={12}>
                {values.TicketId &&
                <>
                    <Typography className={classes.text} component="h4" variant="h6">
                        Le ticket suivant a été mis à jour grâce aux informations que vous nous avez fournies :
                    </Typography>
                    <Typography className={classes.text} component="h4" variant="h6">
                        Numéro de ticket : {values.Ticket}
                    </Typography>
                </>
                }
                {!values.TicketId &&
                <>
                    <Typography className={classes.text} component="h4" variant="h6">
                        Un ticket déclarant un dommage sur le réseau a été ouvert grâce aux informations que vous nous avez fournies:
                    </Typography>
                    <Typography className={classes.text} component="h4" variant="h6">
                        Numéro de ticket : {values.Ticket}
                    </Typography>
                    <Typography className={classes.text} component="h4" variant="h6">
                        Incident grave: {values.Danger === "0" ? 'Non' : 'Oui'}
                    </Typography>
                    <Typography className={classes.text} component="h4" variant="h6">
                        Localisation: {values.X} / {values.Y}
                    </Typography>
                    <Typography className={classes.text} component="h4" variant="h6">
                        Equipement: {values.Structure}
                    </Typography>
                    <Typography className={classes.text} component="h4" variant="h6">
                        Défaut de l'équipement: {values.Defaut}
                    </Typography>
                </>
                }
                <Typography className={classes.text} component="h4" variant="h6">
                    Commentaire: {values.ComStructure}
                </Typography>

                {(values.StatutDeposant === "Auteur") &&
                <>
                    <Typography className={classes.text} component="h4" variant="h6">
                        Nom: {values.Nom}
                    </Typography>
                    <Typography className={classes.text} component="h4" variant="h6">
                        Prénom: {values.Prenom ? values.Prenom : 'Aucun'}
                    </Typography>
                    <Typography className={classes.text} component="h4" variant="h6">
                        Numéro de téléphone: {values.Telephone ? values.Telephone : 'Aucun'}
                    </Typography>
                </>
                }
                {(values.StatutDeposant === "Auteur" || values.StatutDeposant === "Victime" || values.Informe === "1") &&
                <>
                    <Typography className={classes.text} component="h4" variant="h6">
                        Adresse mail fournie: {values.Mail}
                    </Typography>
                    <Typography className={classes.text} component="h4" variant="h6">
                        Un mail de notification vous sera envoyé lorsque le ticket sera résolu.
                    </Typography>
                </>
                }
            </Grid>
        </Grid>
    );
};

export default Resume;
