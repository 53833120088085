import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {MuiThemeProvider, createMuiTheme} from "@material-ui/core/styles";
import {SnackbarProvider} from 'notistack';

const theme = createMuiTheme({
    palette: {
        primary: {
            main: '#003B5E'
        },
        secondary: {
            main: '#EF9700'
        }
    }
});


ReactDOM.render(
    <React.StrictMode>
        <SnackbarProvider>
            <MuiThemeProvider theme={theme}>
                <App/>
            </MuiThemeProvider>
        </SnackbarProvider>
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
