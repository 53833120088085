import React from 'react';
import '../../App.css';
import {
    Grid, InputLabel,
    Typography,
} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import {useField, useFormikContext} from 'formik';
import SelectField from "../../components/FormFields/SelectField";
import InputField from "../../components/FormFields/InputField";


const useStyles = makeStyles((theme) => ({
    title: {
        textAlign: 'center',
        margin: '0.25em',
        fontSize: '1.75em',
        fontWeight: 'bold'
    },
    input: {
        padding: '1em !important'
    },
    form: {
        width: '80%',
        margin: 'auto',
        borderRadius: '1em',
        boxShadow: '1px 1px 12px #555'
    },
    sectionTitle: {
        backgroundColor: '#EF9700',
        borderRadius: '1em 1em 0 0',
        color: 'white',
        width: '100%'
    },
    text: {
        textAlign: 'justify',
        fontSize: '0.90em',
        margin: '1em'
    },
    innerinput: {
        width: '100%'
    },
}));
const statutVal = [
    {
        value: 'Auteur',
        label: 'Auteur'
    },
    {
        value: 'Temoin',
        label: 'Témoin'
    },
    {
        value: 'Victime',
        label: 'Victime'
    }
];
const qualiteVal = [
    {
        value: 'Interne',
        label: 'Interne'
    },
    {
        value: 'Particulier',
        label: 'Particulier'
    },
    {
        value: 'Collectivite',
        label: 'Collectivité'
    },
    {
        value: 'Entreprise',
        label: 'Entreprise'
    }
];
const informeVal = [
    {
        value: '0',
        label: 'Non'
    },
    {
        value: '1',
        label: 'Oui'
    }
];

const InfoDeposant = (props) => {

    const classes = useStyles();
    const {values, submitForm} = useFormikContext();
    const {
        formField: {
            QualiteDeposant,
            Nom,
            Prenom,
            // Adresse,
            // CP,
            // Ville,
            Informe,
            Mail,
            StatutDeposant,
            Telephone
        }
    } = props;

    return (
        <Grid container spacing={1} direction="column" className={classes.form}>
            <Grid className={classes.sectionTitle} item xs={12}>
                <Typography className={classes.title} component="h3" variant="h5">
                    Informations déposant
                </Typography>
            </Grid>
            <Grid className={classes.input} item xs={12}>
                <SelectField
                    className={classes.innerinput}
                    name={StatutDeposant.name}
                    label={StatutDeposant.label}
                    data={statutVal}
                />
            </Grid>
            <Grid className={classes.input} item xs={12}>
                <SelectField
                    className={classes.innerinput}
                    name={QualiteDeposant.name}
                    label={QualiteDeposant.label}
                    data={qualiteVal}
                />
            </Grid>
            {values.StatutDeposant &&
            <>
                <>
                    <Grid className={classes.input} item xs={12}>
                        <InputField
                            className={classes.innerinput}
                            name={Nom.name}
                            label={Nom.label}
                        />
                    </Grid>
                    <Grid className={classes.input} item xs={12}>
                        <InputField
                            className={classes.innerinput}
                            name={Prenom.name}
                            label={Prenom.label}
                        />
                    </Grid>
                    <Grid className={classes.input} item xs={12}>
                        <InputField
                            className={classes.innerinput}
                            name={Telephone.name}
                            label={Telephone.label}
                        />
                    </Grid>
                </>
                {(values.StatutDeposant === 'Temoin') &&
                <Grid className={classes.input} item xs={12}>
                    <SelectField
                        className={classes.innerinput}
                        name={Informe.name}
                        label={Informe.label}
                        data={informeVal}
                    />
                </Grid>
                }
                {(values.Informe === '1' || values.StatutDeposant === 'Auteur'  || values.StatutDeposant === 'Victime') &&
                <Grid className={classes.input} item xs={12}>
                    <InputField
                        className={classes.innerinput}
                        name={Mail.name}
                        label={Mail.label}
                    />
                </Grid>
                }
                <Grid item xs={12}>
                    <Typography className={classes.text} component="h4" variant="h6">
                        Dans le cadre du Règlement Général sur la Protection des Données (RGPD), nous nous engageons
                        à conserver vos données uniquement jusqu'à résolution du signalement que vous effectuez.
                    </Typography>
                </Grid>
            </>
            }

        </Grid>
    );
};

export default InfoDeposant;
