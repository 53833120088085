import React, {useEffect} from 'react';
import '../../App.css';
import {
    Grid,
    Typography,
} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import ReCAPTCHA from "react-google-recaptcha";
import {useFormikContext} from "formik";
import {MarcheMUX} from "../../services";


const useStyles = makeStyles((theme) => ({
    title: {
        textAlign: 'center',
        margin: '0.25em',
        fontSize: '1.75em',
        fontWeight: 'bold'
    },
    inputRecaptcha: {
        padding: '1em !important',
        textAlign: 'center'
    },
    form: {
        width: '80%',
        margin: 'auto',
        borderRadius: '1em',
        boxShadow: '1px 1px 12px #555'
    },
    sectionTitle: {
        backgroundColor: '#EF9700',
        borderRadius: '1em 1em 0 0',
        color: 'white',
        width: '100%'
    },
    text: {
        textAlign: 'justify',
        fontSize: '0.90em',
        margin: '1em'
    },
    innerinput: {
        width: '100%'
    },
}));

const Validation = ({label, ...props}) => {

    const classes = useStyles();
    const recaptchaRef = React.createRef();
    const {values, submitForm} = useFormikContext();

    function onChange(value) {
        if(value){
            values.Captcha = 1
        }else{
            values.Captcha = 0
        }
    }

    return (
        <Grid container spacing={1} direction="column" className={classes.form}>
            <Grid className={classes.sectionTitle} item xs={12}>
                <Typography className={classes.title} component="h3" variant="h5">
                    Vérification CAPTCHA
                </Typography>
            </Grid>
            <Grid className={classes.inputRecaptcha} item xs={12}>
                {(values && values.Captcha) === "0" &&
                <Typography className={classes.text} component="h4" variant="h6">
                    Veuillez réessayer de valider le Captcha
                </Typography>
                }
                {/*DEV : 6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI*/}
                {/*PROD: 6LdMw4UaAAAAAAQ2EeJsYbLyTpKOk02lV0cOiemM*/}
                <ReCAPTCHA
                    sitekey="6LdMw4UaAAAAAAQ2EeJsYbLyTpKOk02lV0cOiemM"
                    onChange={onChange}
                    size="compact"
                    ref={recaptchaRef}
                />
            </Grid>
        </Grid>
    );
};

export default Validation;
