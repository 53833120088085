import CryptoJS from 'crypto-js';

export const cryptageMUX = (routeAEncrypter) => {
  const unencryptedRoute = routeAEncrypter;

  const salt = CryptoJS.lib.WordArray.random(128 / 8).toString(CryptoJS.enc.Hex);
  const initialVector = CryptoJS.lib.WordArray.random(128 / 8).toString(CryptoJS.enc.Hex);
  const password = '!jprDEFtcgcIDsha';


  const key = CryptoJS.PBKDF2(
    password,
    CryptoJS.enc.Hex.parse(salt),
    { keySize: 128 / 32, iterations: 1000 },
  );

  const encrypted = CryptoJS.AES.encrypt(
    unencryptedRoute,
    key,
    { iv: CryptoJS.enc.Hex.parse(initialVector) },
  );


  const encryptedRequest = encrypted.ciphertext.toString(CryptoJS.enc.Base64);

  // Constuire la route de la manière suivant : initialVector::salt::route_en_aes. Le tout encodé en base64.
  const fullEncryptedRoute = btoa(`${initialVector}::${salt}::${encryptedRequest}`);
  return fullEncryptedRoute;
};
