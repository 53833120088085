import axios from '../config/axios';

const GET_EMPLACEMENTS_PROCHES = 'api/Irris/get-emplacements-by-latLongDistance-withLastTicketDommageReseau';
const POST_CREATE_TICKET = 'api/Irris/create-trouble-ticket-reseau';
const POST_ADD_VIE_TICKET = 'api/Irris/creation-action-vie-ticket-of-trouble-ticket';


export const getEmplacementsProches = (x, y, dist, token) => {
    return axios.get(GET_EMPLACEMENTS_PROCHES, {
        params: {
            longitude: x,
            latitude: y,
            distance: dist,
            token: token
        }
    });
};

export const postCreateTicket = (ticket, transition, fichiers, token) => {
    const formData = new FormData();
    formData.append('ticket', JSON.stringify(ticket));
    formData.append('transition', transition);
    formData.append('token', token);
    for (let i = 0; i < fichiers.length; i++) {
        formData.append('fichiers[]', fichiers[i]);
    }
    return axios.post(POST_CREATE_TICKET, formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    });
};

export const postAddVieTicket = (idTroubleTicket, messageContent, niveauConfidentialite, token) => {
    return axios.post(POST_ADD_VIE_TICKET, {
        "idTroubleTicket": idTroubleTicket,
        "messageContent": messageContent,
        "niveauConfidentialite": niveauConfidentialite,
        "token": token,
        "modificationTicket": true,
        "modificationFils":false
    });
};


