import React, {useEffect, useState} from 'react';
import './App.css';
import {
    Grid,
    Typography,
    Button,
    Fade,
    Backdrop,
    CircularProgress,
} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import {Formik, Form} from 'formik';
import Danger from "./views/Danger";
import Geolocalisation from "./views/Emplacement/Geolocalisation";
import Carto from "./views/Emplacement/Carto";
import Validation from "./views/Validation";
import InfoDeposant from "./views/InfoDeposant";
import Resume from "./views/Resume";
import LogoDsp from "./components/LogoDsp";

import validationSchema from './components/FormModel/validationSchema';
import checkoutFormModel from './components/FormModel/checkoutFormModel';
import formInitialValues from './components/FormModel/formInitialValues';
import Remerciement from "./views/Remerciement";
import {postDeclaration} from './ressources/services/declaration'
import {postCreateTicket, postAddVieTicket} from './ressources/services/irris'
import moment from 'moment'
import Erreur from "./views/Erreur";

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: '#f5f5f5',
        minHeight: '100vh',
        padding: theme.spacing(2)
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
    },
    title: {
        color: '#003B5E',
        textAlign: 'center',
        margin: '0.25em',
        fontSize: '3em',
        fontWeight: 'bold',
        "@media (max-width: 700px)": {
            fontSize: '2em',
        }
    },
    text: {
        textAlign: 'justify',
        margin: '1em',
        fontSize: '1em',
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
    },
    input: {
        padding: '1em !important'
    },
    copyright: {
        color: "lightgray",
        fontSize: '0.75em',
    },
    altiIcon: {
        width: '30%',
        "@media (max-width: 700px)": {
            width: '50% !important'
        }
    },
    altiIconMin: {
        width: '40%'
    },
    iconGrid: {
        textAlign: 'center'
    },
    iconGridMin: {
        textAlign: 'right',
        marginLeft: '30%',
        fontSize: '0.8em'
    },
    buttonField: {
        margin: '2em 0 0 0',
        padding: '0.5em !important',
        width: '100%'
    },
    button: {
        backgroundColor: '#003B5E',
        color: 'white',
        fontWeight: 'bold'
    },
    form: {
        width: '100% !important'
    }
}));

const {formId, formField} = checkoutFormModel;
const steps = ['Danger', 'Geolocalisation', 'Carto', 'Validation', 'Resume', 'Remerciement'];

function _renderStepContent(step, dsp, erreur) {
    switch (step) {
        case 0:
            return <Danger formField={formField}/>;
        case 1:
            return <Geolocalisation formField={formField}/>;
        case 2:
            return <Carto formField={formField} activeDsp={dsp}/>;
        case 3:
            return <InfoDeposant formField={formField}/>;
        case 4:
            return <Validation/>;
        case 5:
            return <Resume/>;
        case 6:
            return <Remerciement/>;
        case 7:
            return <Erreur erreur={erreur}/>
        default:
            return <div>Not Found</div>;
    }
}

const App = () => {
    const classes = useStyles();

    const [loading, setLoading] = useState(false);
    const [activeStep, setActiveStep] = useState(0);
    const [activeDsp, setActiveDsp] = useState();
    const [validationErreur, setValidationErreur] = useState(0);

    const currentValidationSchema = validationSchema[activeStep];
    const isLastStep = activeStep === steps.length - 2;
    const isConfStep = activeStep === 2;
    const isCaptchaStep = activeStep === 4;

    useEffect(() => {
        const urlParam = window.location.search;
        const param = new URLSearchParams(urlParam);
        const dsp = param.get('DSP');

        setActiveDsp(dsp);
    }, []);

    function _submitForm(values, actions) {
        if (values.Ticket === "1") {
            _createTicket(values, actions)
        } else {
            _addVieTicket(values, actions)
        }
    }

    async function _createTicket(values, actions) {
        let infosTicket = {};

        let symptomeTicket = values.StatutDeposant + ' - ' + values.QualiteDeposant + '\n' + values.ReferenceEquipement + ' -  Signalé comme : ' + values.Structure;
        if (values.Structure === 'Poteau') {
            symptomeTicket += '\nDefaut signalé : ' + values.Defaut;
        }
        if (values.Danger === '1') {
            symptomeTicket += '\nLe dommage a été signalé comme dangereux par le déclarant';
        }
        symptomeTicket += '\nCommentaire : ' + values.ComStructure;

        infosTicket = {

            idDemandeur: 915, //PROD
            // idDemandeur: 799, // DEV
            troubleDescription: symptomeTicket,
            troubleDetectionTime: moment(Date()).format(),
            idEmplacement: values.ReferenceEquipement,
            defectClassId: 1001,
            categorieTicket: 4,
            GRTDelay: 240,
            GRTSignalingRangeDay: "5J/7",
            GRTSignalingRangeHour: "24h/24",
            GRTRecoveryRange: "SIGNALISATION",
        };

        const fichiers = values.Files;
        const token = 'token';

        postCreateTicket(infosTicket, "QUEUED", fichiers, token).then(response => {
            actions.setSubmitting(false);
            values.Ticket = response.data.troubleTicketKey;
            _saveTicket(values, actions)
        }).catch(function (error) {
            actions.setSubmitting(false);
            setLoading(false)
            setActiveStep(7)
            setValidationErreur(1)
        });
    }

    async function _addVieTicket(values, actions) {
        const idTroubleTicket = values.TicketId;
        const messageContent = values.ComStructure;
        const niveauConfidentialite = "PUBLIC";
        const token = 'token'

        postAddVieTicket(idTroubleTicket, messageContent, niveauConfidentialite, token).then(response => {
            actions.setSubmitting(false);
            _saveTicket(values, actions)
        }).catch(function (error) {
            actions.setSubmitting(false);
            setLoading(false)
            setActiveStep(7)
            setValidationErreur(2)
        });
    }

    async function _saveTicket(values, actions) {
        postDeclaration(values).then(response => {
            actions.setSubmitting(false);
            setActiveStep(activeStep + 1);
            setLoading(false)
        }).catch(function (error) {
            actions.setSubmitting(false);
            setLoading(false)
            setActiveStep(7)
            setValidationErreur(3)
        });
    }

    function _handleSubmit(values, actions) {
        if (isLastStep) {
            setLoading(true)
            _submitForm(values, actions);
        } else if (isConfStep && values.Confirmation === "0") {
            setActiveStep(6);
            actions.setTouched({});
            actions.setSubmitting(false);
        } else if (isCaptchaStep && values.Captcha === "0") {
            actions.setTouched({});
            actions.setSubmitting(false);
        } else {
            setActiveStep(activeStep + 1);
            actions.setTouched({});
            actions.setSubmitting(false);
        }
    }

    function _handleBack() {
        setActiveStep(activeStep - 1);
    }

    return (
        <Grid
            component="main"
            container
            className={classes.root}
            alignItems="center"
            direction="column"
        >
            <Fade in={loading}>
                <Backdrop className={classes.backdrop} open={true}>
                    <CircularProgress color="secondary"/>
                </Backdrop>
            </Fade>
            {/*<Grid item xs={12} sm={10} md={8} className={classes.content}>*/}
            <Grid item className={classes.content}>
                <Grid container justify="space-around" spacing={1}>
                    <Grid item xs={12} sm={10} md={8} className={classes.iconGrid}>
                        {!activeDsp &&
                        <img alt="Altitude Infrastructure" src="/img/Logo_AI_NEW.png"
                             className={classes.altiIcon}/>
                        }
                        {activeDsp &&
                        <LogoDsp activeDsp={activeDsp}/>
                        }
                    </Grid>
                </Grid>
                <Typography className={classes.title} component="h1">
                    Déclaration de dommages réseaux
                </Typography>

                <Formik
                    initialValues={formInitialValues}
                    validationSchema={currentValidationSchema}
                    onSubmit={_handleSubmit}
                >
                    {({isSubmitting}) => (
                        <Form id={formId} className={classes.form}>
                            {_renderStepContent(activeStep, activeDsp, validationErreur)}
                            {(activeStep < 5) &&
                            <Grid item xs={12} className={classes.buttonField}>
                                <Grid container justify="space-around" spacing={1}>
                                    {activeStep !== 0 && (
                                        <Grid item xs={4}>
                                            <Button className={classes.button} fullWidth variant="contained"
                                                    onClick={_handleBack}
                                            ><i className={classes.button}/>Retour</Button>
                                        </Grid>
                                    )}
                                    <Grid item xs={4}>
                                        <Button disabled={isSubmitting} className={classes.button} fullWidth
                                                variant="contained" type="submit"
                                        ><i className={classes.button}/> {isLastStep ? 'Valider' : 'Suivant'}
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                            }
                        </Form>
                    )}
                </Formik>
                <Grid container justify="center" className={classes.iconGrid}>
                    <p className={classes.copyright}>Déclaration de dommages réseaux -
                        © {new Date().getFullYear()} Altitude
                        Infra.</p>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default App;
