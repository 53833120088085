import React from 'react';
import '../../App.css';
import {
    Grid,
    Typography,
    InputLabel,
    MenuItem,
} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import SelectField from "../../components/FormFields/SelectField";
import {useFormikContext} from "formik";


const useStyles = makeStyles((theme) => ({
    title: {
        textAlign: 'center',
        margin: '0.25em',
        fontSize: '1.75em',
        fontWeight: 'bold'
    },
    input: {
        padding: '1em !important'
    },
    form: {
        width: '80%',
        margin: 'auto',
        borderRadius: '1em',
        boxShadow: '1px 1px 12px #555'
    },
    textAccueil: {
        textAlign: 'center',
        margin: '2%',
        fontSize: '1em',
    },
    textAstérix: {
        textAlign: 'center',
        margin: '1%',
        fontSize: '0.8em',
    },
    sectionTitle: {
        backgroundColor: '#EF9700',
        borderRadius: '1em 1em 0 0',
        color: 'white',
        width: '100%'
    },
    textDanger: {
        textAlign: 'justify',
        fontSize: '0.90em',
        fontWeight: 'bold',
        margin: '1em'
    },
    innerinput: {
        width: '100%'
    },
	textBleu: {
        textAlign: 'center',
        margin: '2%',
        fontSize: '1.5em',
		fontWeight: 'bold',
		color: '#003B5E',
		whiteSpace: 'pre-line'
    },
}));
const dangerVal = [
    {
        value: '0',
        label: 'Non'
    },
    {
        value: '1',
        label: 'Oui'
    }
];

const Danger = (props) => {

    const classes = useStyles();
    const {values, submitForm} = useFormikContext();
    const {
        formField: {
            Danger
        }
    } = props;

    return (
        <>
            <Typography className={classes.textAccueil} component="h4" variant="h6">
				Vous rencontrez une panne sur votre ligne personnelle ? Vous devez déclarer votre incident directement auprès de votre opérateur commercial, qui vous donnera accès au suivi de sa résolution.
			</Typography>
			<Typography className={classes.textAccueil} component="h4" variant="h6">
                ***   ***
			</Typography>
			<Typography className={classes.textBleu} component="h4" variant="h6">
				Vous avez constaté un dommage visible depuis la voie publique sur les infrastructures du réseau de fibre optique : poteau, câble ou armoire détérioré(e) ? <br />
				Le site dommage réseau permet à toute personne, particulier ou professionnel, de nous signaler ce dernier,<br />offrant ainsi une réparation rapide pour le bien de tous les usagers.
            </Typography>
            <Typography className={classes.textAstérix} component="h4" variant="h6">
                Les champs notés d'un * sont obligatoires.
            </Typography>
            <Grid container spacing={1} direction="column" className={classes.form}>
                <Grid className={classes.sectionTitle} item xs={12}>
                    <Typography className={classes.title} component="h3" variant="h5">
                        Gravité de l'incident
                    </Typography>
                </Grid>
                <Grid className={classes.input} item xs={12}>
                    {/*<SelectField*/}
                    {/*    className={classes.innerinput}*/}
                    {/*    name={Danger.name}*/}
                    {/*    label={Danger.label}*/}
                    {/*    data={dangerVal}*/}
                    {/*/>*/}
                    <Typography className={classes.textDanger} component="h4" variant="h6">
                        Un danger grave est susceptible de mettre en cause l'intégrité physique des personnes.
                        Afin de sécuriser les lieux, veuillez tout d'abord contacter les services d'urgence en appelant
                        le 112 (Appel gratuit).
                    </Typography>
                </Grid>
            </Grid>

        </>
    );
};

export default Danger;
