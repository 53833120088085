export default {
    formId: 'checkoutForm',
    formField: {
        Danger: {
            name: 'Danger',
            label: 'Incident grave*',
            requiredErrorMsg: 'Ce champs est obligatoire'
        },
        Geoloc: {
            name: 'Geoloc',
            label: 'Activer la géolocalisation*',
            requiredErrorMsg: 'Ce champs est obligatoire'
        },
        Confirmation: {
            name: 'Confirmation',
            label: 'Commenter l\'incident déclaré?*',
            requiredErrorMsg: 'Ce champs est obligatoire'
        },
        X: {
            name: 'X',
            label: 'X*',
            requiredErrorMsg: 'Ce champs est obligatoire'
        },
        Y: {
            name: 'Y',
            label: 'Y*',
            requiredErrorMsg: 'Ce champs est obligatoire'
        },
        Ticket: {
            name: 'Ticket',
            label: 'Ticket existant correspondant*',
            requiredErrorMsg: 'Ce champs est obligatoire'
        },
        TicketId: {
            name: 'TicketId',
            label: 'TicketId*',
            requiredErrorMsg: 'Ce champs est obligatoire'
        },
        StatutDeposant: {
            name: 'StatutDeposant',
            label: 'Signaler en tant que',
            requiredErrorMsg: 'Ce champs est obligatoire'
        },
        QualiteDeposant: {
            name: 'QualiteDeposant',
            label: 'Qualité du déposant*',
            requiredErrorMsg: 'Ce champs est obligatoire'
        },
        Structure: {
            name: 'Structure',
            label: 'Type d’infrastructure*',
            requiredErrorMsg: 'Ce champs est obligatoire'
        },
        ComStructure: {
            name: 'ComStructure',
            label: 'Descriptif du dommage*',
            requiredErrorMsg: 'Ce champs est obligatoire'
        },
        Defaut: {
            name: 'Defaut',
            label: 'Type de défaut*',
            requiredErrorMsg: 'Ce champs est obligatoire'
        },
        Nom: {
            name: 'Nom',
            label: 'Nom*',
            requiredErrorMsg: 'Ce champs est obligatoire'
        },
        Prenom: {
            name: 'Prenom',
            label: 'Prénom',
            requiredErrorMsg: 'Ce champs est obligatoire'
        },
        Informe: {
            name: 'Informe',
            label: 'Être informé(e)*',
            requiredErrorMsg: 'Ce champs est obligatoire'
        },
        Mail: {
            name: 'Mail',
            label: 'Mail*',
            requiredErrorMsg: 'Ce champs est obligatoire',
            invalidErrorMsg: 'Adresse mail non valide'
        },
        Telephone: {
            name: 'Telephone',
            label: 'Telephone',
            invalidErrorMsg: 'Numéro non valide'
        },
        ReferenceEquipement: {
            name: 'ReferenceEquipement',
            label: 'Reference Equipement',
            requiredErrorMsg: 'Ce champs est obligatoire'
        },
        Captcha: {
            name: 'Captcha',
            label: 'Captcha',
            requiredErrorMsg: 'Ce champs est obligatoire'
        }
    }
};
