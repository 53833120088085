import {React, useEffect, useState,useMemo} from 'react';
import '../../../App.css';
import {
    CircularProgress,
    Grid, MenuItem, TextField, Typography,
} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import {MapContainer, TileLayer, Marker, Popup, useMapEvents, Polyline, Polygon, GeoJSON, useMap} from 'react-leaflet'
import {useFormikContext} from "formik";
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import {MarcheMUX, Adresse} from '../../../services';
import icon from '../../../ressources/img/marker-icon.png';
import emplacement from '../../../ressources/img/emplacement.png';
import emp from '../../../ressources/img/emp.png';
import personne2 from '../../../ressources/img/personne2.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';
import axios from 'axios';
import {Autocomplete} from "@material-ui/lab";
import {getEmplacementsProches} from "../../../ressources/services/irris";
import {useSnackbar} from 'notistack';

let DefaultIcon = L.icon({
    iconUrl: icon,
    shadowUrl: iconShadow,
    iconSize: [25, 41],
    iconAnchor: [10, 41],
    popupAnchor: [2, -40],
});

let EmpIcon = L.icon({
    iconUrl: emp,
    iconSize: [20, 20],
    iconAnchor: [10,20],
    popupAnchor: [5, -10],
    shadowUrl: null,
    shadowSize: null,
    shadowAnchor: null,
});

let PersIcon = L.icon({
    iconUrl: personne2,
    iconSize: [25, 25],
    iconAnchor: [5, 10],
    popupAnchor: [5, -10],
    shadowUrl: null,
    shadowSize: null,
    shadowAnchor: null,
});

L.Marker.prototype.options.icon = DefaultIcon;

const useStyles = makeStyles((theme) => ({
    input: {
        padding: '1em !important'
    },
    map: {
        height: '100px'
    },
    text: {
        textAlign: 'justify',
        fontSize: '0.8em',
        marginBottom: '0.5em'
    },
}));

const Carte = (props) => {

    const classes = useStyles();
    const {values, submitForm} = useFormikContext();
    const {enqueueSnackbar, closeSnackbar} = useSnackbar();

    const [newMarker, setNewMarker] = useState();
    const [mapCenter, setMapCenter] = useState([]);
    const [mapBounds, setMapBounds] = useState([[52.126615, -7.366685], [40.225118, 11.582297]]);
    const [zoom, setZoom] = useState();
    const [marche, setMarche] = useState()
    const [emplacementsProches, setEmplacementsProches] = useState()
    const [isLoadingAdresse, setIsLoadingAdresse] = useState(false);
    // Token Adresse
    const [cancelTokenAdresse, setCancelTokenAdresse] = useState(axios.CancelToken.source());
    const [abortControllerAdresse, setAbortControllerAdresse] = useState(new window.AbortController());
    // Partie EmplacementsAdresse
    const [emplacementsAdresse, setEmplacementsAdresse] = useState([]);
    const [adresses, setAdresses] = useState([]);
    const [selectedIndex, setSelectedIndex] = useState();


    useEffect(() => {

        if (values.X && values.Y) {
            setNewMarker([values.Y, values.X]);
            setMapCenter([values.Y, values.X]);
            setZoom(17);
        }
        Promise.all([MarcheMUX.getContoursMarches().catch((error) => {
        })])
            .then(async (responses) => {
                if (responses[0]) {
                    const listeMarche = [];
                    for(let i = 0; i<responses[0].features.length; i++) {
                        if (responses[0].features[i].properties.libelleMarche !== 'ROSACE' && responses[0].features[i].properties.libelleMarche !== 'LOSANGE') {
                            listeMarche.push(responses[0].features[i]);
                        }
                    }
                    setMarche(listeMarche);
                    if (!values.X && !values.Y) {
                        defineMarche(responses[0].features);
                    }
                }
            })
            .catch(function (error) {
                enqueueSnackbar('Une erreur est survenue lors du chargement de la carte, veuillez réessayer.', {
                    variant: 'error',
                    autoHideDuration: 2000,
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right'
                    }
                });
            });
    }, []);


    function useFriendStatus() {

        const map = useMap()
        map.setView(newMarker, zoom)
    }

    const defineMarche = (listeMarche) => {
        const paramMarche = defineLibelleMarche(props.activeDsp);

        if (paramMarche[0] !== 'AI') {
            findMarcheCentre(paramMarche, listeMarche);
        } else {
            setMapCenter(['46.60611', '1.87528']);
            setZoom(5);
        }
    }

    const defineLibelleMarche = (activeDsp) => {
        let marche = [];
        switch (activeDsp) {
            case 'Corai':
                marche = ['CORAI',8];
                return marche;
            case 'Dioptic':
                marche = ['DIOPTIC',8];
                return marche;
            case 'Doubs':
                marche = ['DIOPTIC',8];
                return marche;
            case 'Emeraude':
                marche = ['EMERAUDE THD',9];
                return marche;
            case 'Fibre31':
                marche = ['FIBRE 31',8];
                return marche;
            case 'LaFibre06':
                marche = ['LA FIBRE 06',9];
                return marche;
            case 'LF85':
                marche = ['LA FIBRE 85',9];
                return marche;
            case 'Losange':
                marche = ['LOSANGE',7];
                return marche;
            case 'Manche':
                marche = ['MANCHE FIBRE',8];
                return marche;
            case 'NetGR':
                marche = ['NET GRAND RODEZ',11];
                return marche;
            case 'Octogone':
                marche = ['OCTOGONE FIBRE',9];
                return marche;
            case 'PIXL':
                marche = ['PIXL',8];
                return marche;
            case 'Prisme':
                marche = ['PRISME',8];
                return marche;
            case 'Resoptic':
                marche = ['RESOPTIC',11];
                return marche;
            case 'Rev@':
                marche = ['REV@',10];
                return marche;
            case 'THD66':
                marche = ['NUMERIQUE 66',9];
                return marche;
            case 'TPMTHD':
                marche = ['THD 83',10];
                return marche;
            case 'Yconik':
                marche = ['YCONIK',9];
                return marche;
            case 'Rosace':
                marche = ['ROSACE',8];
                return marche;
            case 'Vendee':
                marche = ['VENDEE NUMERIQUE',8];
                return marche;
            case 'GrandNancy':
                marche = ['GRAND NANCY',11];
                return marche;
            case 'CoeurCoteFleurie':
                marche = ['COEUR COTE FLEURIE',11];
                return marche;
            case 'SommeFibre':
                marche = ['SOMME',8];
                return marche;
            case 'SeineEssonne':
                marche = ['SEINE ESSONNE THD',11];
                return marche;
            case 'Oxygene':
                marche = ['OXYGENE',11];
                return marche;
            case 'Sequantic':
                marche = ['SEQUANTIC',11];
                return marche;
            case 'Syane':
                marche = ['SYANE',9];
                return marche;
            case 'Calvados':
                marche = ['CALVADOS',9];
                return marche;
            case 'EuropEssonne':
                marche = ['EUROP ESSONNE',11];
                return marche;
            case 'Dunkerque':
                marche = ['DUNKERQUE',10];
                return marche;
            default:
                marche = ['AI'];
                return marche;
        }
    };


    const findMarcheCentre = (paramMarche, listeMarche) => {
        let midX;
        let midY;
        listeMarche.map((item) => {
            if (item.properties.libelleMarche === paramMarche[0]) {
                const bounds = getBoundsOfGeojson(item);
                if (typeof (bounds.xMin) !== 'undefined' && typeof (bounds.xMax) !== 'undefined'
                    && typeof (bounds.yMin) !== 'undefined' && typeof (bounds.yMax) !== 'undefined') {
                    midX = (bounds.xMin + bounds.xMax) / 2;
                    midY = (bounds.yMin + bounds.yMax) / 2;
                    setMapCenter([midY, midX]);
                    setZoom(paramMarche[1]);
                }
            }
        })
    };


    const recuperationAdresse = (input) => {
        if (input.length > 3) {
            setIsLoadingAdresse(true);
        } else {
            return false;
        }

        cancelTokenAdresse.cancel();
        abortControllerAdresse.abort();
        const canceltok = axios.CancelToken.source();
        setCancelTokenAdresse(canceltok);

        Adresse.getAdresseFromString(input, canceltok).then((response) => {
            setAbortControllerAdresse(new window.AbortController());
            if (response.data) {
                // Récupération des adresses dans la réponse, et ajout d'un label pour l'affichage en liste
                const listAdresses = response.data.features.map((adresse) => {
                    adresse.label = adresse.properties.label;
                    return adresse;
                });
                setAdresses(listAdresses);
                setIsLoadingAdresse(false);
            }
        }).catch((error) => {
            if (axios.isCancel(error)) {
                setIsLoadingAdresse(true);
            } else {
                setIsLoadingAdresse(false);
                enqueueSnackbar('Une erreur est survenue lors du chargement de l\'adresse, veuillez réessayer.', {
                    variant: 'error',
                    autoHideDuration: 2000,
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right'
                    }
                });
            }
        });
    };

    const placeMarkerAutocomplete = (item) => {
        if (item) {
            if (isPointInZAMarche(item.geometry.coordinates[1], item.geometry.coordinates[0])) {
                setNewMarker([item.geometry.coordinates[1], item.geometry.coordinates[0]]);
                setMapCenter([item.geometry.coordinates[1], item.geometry.coordinates[0]]);
                if (zoom >= 5) {
                    setZoom(17);
                }
                getListeEmplacements(item.geometry.coordinates[0], item.geometry.coordinates[1]);
            }
        }
    };

    const getBoundsOfGeojson = (data) => {
        const bounds = {};
        const {coordinates} = data.geometry;
        const idMarche = data.properties.codeMarche;
        if (coordinates.length === 1) {
            // It's only a single Polygon
            // For each individual coordinate in this feature's coordinates...
            for (let j = 0; j < coordinates[0].length; j += 1) {
                // Update the bounds recursively by comparing the current xMin/xMax and yMin/yMax with the current coordinate
                bounds.xMin = bounds.xMin < coordinates[0][j][0] ? bounds.xMin : coordinates[0][j][0];
                bounds.xMax = bounds.xMax > coordinates[0][j][0] ? bounds.xMax : coordinates[0][j][0];
                bounds.yMin = bounds.yMin < coordinates[0][j][1] ? bounds.yMin : coordinates[0][j][1];
                bounds.yMax = bounds.yMax > coordinates[0][j][1] ? bounds.yMax : coordinates[0][j][1];
            }
        } else if (idMarche === 48 || idMarche === 52 || idMarche === 73) {
            for (let j = 0; j < coordinates.length; j += 1) {
                // For each individual coordinate in this coordinate set...
                for (let k = 0; k < coordinates[j].length; k += 1) {
                    // Update the bounds recursively by comparing the current xMin/xMax and yMin/yMax with the current coordinate
                    bounds.xMin = bounds.xMin < coordinates[j][k][0] ? bounds.xMin : coordinates[j][k][0];
                    bounds.xMax = bounds.xMax > coordinates[j][k][0] ? bounds.xMax : coordinates[j][k][0];
                    bounds.yMin = bounds.yMin < coordinates[j][k][1] ? bounds.yMin : coordinates[j][k][1];
                    bounds.yMax = bounds.yMax > coordinates[j][k][1] ? bounds.yMax : coordinates[j][k][1];
                }
            }
        } else {
            // It's a MultiPolygon
            // Loop through each coordinate set
            for (let j = 0; j < coordinates.length; j += 1) {
                // For each individual coordinate in this coordinate set...
                for (let k = 0; k < coordinates[j][0].length; k += 1) {
                    // Update the bounds recursively by comparing the current xMin/xMax and yMin/yMax with the current coordinate
                    bounds.xMin = bounds.xMin < coordinates[j][0][k][0] ? bounds.xMin : coordinates[j][0][k][0];
                    bounds.xMax = bounds.xMax > coordinates[j][0][k][0] ? bounds.xMax : coordinates[j][0][k][0];
                    bounds.yMin = bounds.yMin < coordinates[j][0][k][1] ? bounds.yMin : coordinates[j][0][k][1];
                    bounds.yMax = bounds.yMax > coordinates[j][0][k][1] ? bounds.yMax : coordinates[j][0][k][1];
                }
            }
        }
        // Returns an object that contains the bounds of this GeoJSON data.
        // The keys describe a box formed by the northwest (xMin, yMin) and southeast (xMax, yMax) coordinates.
        return bounds;
    };

    const isPointInZAMarche = (lat, lng) => {
        let retour = false;
        marche.map((item) => {
            const bounds = getBoundsOfGeojson(item);
            if (typeof (bounds.xMin) !== 'undefined' && typeof (bounds.xMax) !== 'undefined'
                && typeof (bounds.yMin) !== 'undefined' && typeof (bounds.yMax) !== 'undefined') {

                const intersect = ((bounds.yMin <= lat) && (lat <= bounds.yMax)) && ((bounds.xMin <= lng) && (lng <= bounds.xMax));


                if (intersect) retour = true;
            }
        });
        return retour;
    };

    const getListeEmplacements = (X, Y) => {
        const token = 'token';
        getEmplacementsProches(X, Y, 0.01, token).then(response => {
            setEmplacementsProches(response.data.emplacements);
        }).catch(function (error) {
            enqueueSnackbar('Une erreur est survenue lors du chargement des équipements proches, veuillez réessayer.', {
                variant: 'error',
                autoHideDuration: 2000,
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right'
                }
            });
        });
    };

    const getActiveIcon = (index) => {
        if (index === selectedIndex) {
            return DefaultIcon;
        } else {
            return EmpIcon;
        }
    };

    function AfficherMarches() {
        const map = useMap();
        return (
            <GeoJSON data={marche} eventHandlers={{
                click: (e) => {
                    setNewMarker(e.latlng);
                    setMapCenter(e.latlng);
                    if (zoom >= 5) {
                        setZoom(17);
                    }
                    setSelectedIndex('');
                    getListeEmplacements(e.latlng.lng, e.latlng.lat)
                },
            }}/>
        )
    }

    function AfficherMarkerPersonne() {
        const map = useMap();
        useFriendStatus(() => {
        }, [newMarker]);
        return (
            <Marker key={`new-marker`}
                    position={newMarker}
                    icon={PersIcon}
                    eventHandlers={{
                        click: (e) => {
                            // if (isPointInZAMarche(e.latlng.lat, e.latlng.lng)) {
                            setMapCenter(e.latlng);
                            if (zoom >= 5) {
                                setZoom(17);
                            }
                            map.setView(e.latlng, zoom)
                            // }
                        },
                    }}
            >
                <Popup>
                    <span>Centre de la zone d'affichage</span>
                </Popup>
            </Marker>
        )
    }

    const childComponentMemo = useMemo(() => <MapContainer
        center={mapCenter}
        zoom={zoom}
        scrollWheelZoom={true}
        id="mapId"
        maxBoundsViscosity="1.0"
        maxBounds={mapBounds}
        minZoom={5}
        maxZoom={18}
        style={{height: '35em'}}>
        <TileLayer
            attribution={'<a href="https://mux.altitudeinfra.fr:8443">MUX</a> &copy; <a href="https://leafletjs.com" title="A JS library for interactive maps">Leaflet</a> | ©Contributeurs de OpenStreetMap'}
            url="https://tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        {((values.X && values.Y) || newMarker) &&
        <AfficherMarkerPersonne/>
        }
        {emplacementsProches &&
        emplacementsProches.map((item, index) => (
            <Marker key={`new-marker-` + index}
                    index={index}
                    icon={getActiveIcon(index)}
                    position={[item.lat, item.long]}
                    eventHandlers={{
                        click: (e) => {
                            if (selectedIndex !== e.target.options.index) {
                                setSelectedIndex(e.target.options.index);
                                values.X = item.long;
                                values.Y = item.lat;
                                values.ReferenceEquipement = item.id;
                                if (item.troubleTickets.length === 0) {
                                    values.Ticket = '1';
                                    values.TicketId = '';
                                } else {
                                    values.Ticket = item.troubleTickets[0].troubleTicketKey;
                                    values.TicketId = item.troubleTickets[0].id;
                                }
                            } else {
                                setSelectedIndex('');
                                values.X = '';
                                values.Y = '';
                                values.ReferenceEquipement = '';
                                values.Ticket = '';
                                values.TicketId = '';
                            }
                            props.renderFn()
                        },
                    }}
            >
                <Popup>
                    <span>Equipement</span>
                    <br/>
                    {values.Ticket !== '0' && values.Ticket !== '1' && values.Ticket &&
                    <span>Un ticket est présent sur cet équipement</span>
                    }
                </Popup>
            </Marker>
        ))
        }
        <AfficherMarches/>
    </MapContainer>,[mapCenter,marche,mapBounds,zoom,emplacementsProches,newMarker,selectedIndex,values.Ticket,values.TicketId,values.X,values.Y,values.ReferenceEquipement]);

    return (
        <>
            {(mapCenter && zoom && marche) ? <>
                    <Grid className={classes.input} item xs={12}>
                        <div>
                            <Autocomplete
                                options={adresses}
                                noOptionsText={'Aucune adresse trouvée'}
                                getOptionLabel={option => (option ? option["label"] : '')}
                                onChange={(e, item) => {
                                    placeMarkerAutocomplete(item)
                                }}
                                loading={isLoadingAdresse}
                                onInputChange={(e) => {
                                    e.target.value && (e.target.value.length > 3) ? recuperationAdresse(e.target.value) : setEmplacementsAdresse([]);
                                }}
                                filterOptions={(options, state) => options}
                                renderInput={params => (
                                    <TextField
                                        {...params}
                                        placeholder="Saisir une adresse"
                                        InputProps={{
                                            ...params.InputProps,
                                            endAdornment: (
                                                <>
                                                    {isLoadingAdresse ? (
                                                        <CircularProgress color="primary" size={20}/>
                                                    ) : null}
                                                    {params.InputProps.endAdornment}
                                                </>
                                            ),
                                        }}
                                    />
                                )}
                            />
                        </div>
                    </Grid>
                    <Grid className={classes.input} item xs={12}>
                        {childComponentMemo}
                    </Grid>
                </>
                :
                <Grid className={classes.input} item xs={12}>
                    <Grid container justify="center">
                        <CircularProgress color="secondary"/>
                    </Grid>
                </Grid>}
        </>
    );
};

export default Carte;
