import {React, useState} from 'react';
import '../../../App.css';
import {
    Grid,
    Typography,
} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import InputField from "../../../components/FormFields/InputField";
import SelectField from "../../../components/FormFields/SelectField";
import {DropzoneArea} from "material-ui-dropzone";
import {useFormikContext} from "formik";
import Carte from "../Map";

const useStyles = makeStyles((theme) => ({
    title: {
        textAlign: 'center',
        margin: '0.25em',
        fontSize: '1.75em',
        fontWeight: 'bold'
    },
    input: {
        padding: '1em !important'
    },
    equipement: {
        width: '80%'
    },
    form: {
        width: '80%',
        margin: 'auto',
        borderRadius: '1em',
        boxShadow: '1px 1px 12px #555'
    },
    formEquipement: {
        width: '80%',
        margin: '0.5em auto',
        borderRadius: '1em',
        boxShadow: '1px 1px 12px #555'
    },
    sectionTitle: {
        backgroundColor: '#EF9700',
        borderRadius: '1em 1em 0 0',
        color: 'white',
        width: '100%'
    },
    sectionTitleEquipement: {
        backgroundColor: '#003B5E',
        borderRadius: '1em 1em 0 0',
        color: 'white',
        width: '100%',
    },
    text: {
        textAlign: 'justify',
        fontSize: '1em',
        margin: '1em'
    },
    textConf: {
        textAlign: 'justify',
        fontSize: '0.90em',
        margin: '1em 0 0 1em'
    },
    innerinput: {
        width: '100%'
    },
    drawer: {
        width: '40% !important',
        "@media (max-width: 900px)": {
            width: '80% !important'
        }
    },
    drawerPaper: {
        width: '40% !important',
        "@media (max-width: 900px)": {
            width: '80% !important'
        }
    },
    itemEquipement: {
        textAlign: 'center'
    },
    selectItem: {
        display: "flex",
        alignItems: "center"
    },
    imageSelect: {
        width: "50px",
        height: "auto",
        marginRight: "5px"
    }
}));


const Carto = (props) => {

    const classes = useStyles();
    const {values, submitForm} = useFormikContext();
    const [renderAgain, setRenderAgain] = useState(0)
    const {
        formField: {
            Ticket,
            Confirmation,
            Structure,
            ComStructure,
            Defaut,
        }
    } = props;


    const confirmationVal = [
        {
            value: '0',
            label: 'Non'
        },
        {
            value: '1',
            label: 'Oui'
        }
    ];

    const equipementVal = [
        {
            value: '2',
            label: 'Choisir...'
        },
        {
            value: 'Local Technique',
            label: <div className={classes.selectItem}><img className={classes.imageSelect} alt="Dessin shelter"
                                                            src="img/structures/nro.png"/>Local Technique</div>
        },
        {
            value: 'Poteau',
            label: <div className={classes.selectItem}><img className={classes.imageSelect} alt="Dessin poteau"
                                                            src="img/structures/poteau.png"/>Poteau</div>
        },
        {
            value: 'Coffret sur poteau',
            label: <div className={classes.selectItem}><img className={classes.imageSelect} alt="Dessin coffret"
                                                            src="img/structures/pbo.png"/>Coffret sur poteau</div>
        },
        {
            value: 'Armoire sur trottoir',
            label: <div className={classes.selectItem}><img className={classes.imageSelect}
                                                            alt="Dessin armoire sur trottoir"
                                                            src="img/structures/sro.png"/>Armoire sur trottoir</div>
        },
        {
            value: 'Coffret dans chambre souterraine',
            label: <div className={classes.selectItem}><img className={classes.imageSelect}
                                                            alt="Dessin chambre sous-terraine"
                                                            src="img/structures/trappe.png"/>Coffret dans chambre
                souterraine</div>
        },
        {
            value: 'Trappe/Plaque au sol',
            label: <div className={classes.selectItem}><img className={classes.imageSelect} alt="Dessin trappe"
                                                            src="img/structures/trappe.png"/>Trappe/Plaque au sol</div>
        },
        {
            value: 'Câble souterrain',
            label: <div className={classes.selectItem}><img className={classes.imageSelect}
                                                            alt="Dessin câble souterrain"
                                                            src="img/structures/cable.png"/>Câble souterrain</div>
        },
        {
            value: 'Câble aérien',
            label: <div className={classes.selectItem}><img className={classes.imageSelect} alt="Dessin câble aérien"
                                                            src="img/structures/poteau.png"/>Câble aérien</div>
        },
    ];
    const dommageVal = [
        {
            value: '2',
            label: 'Choisir...'
        },
        {
            value: 'Casse',
            label: 'Cassé'
        },
        {
            value: 'Penche',
            label: 'Penché'
        },
        {
            value: 'Manquant',
            label: 'Manquant'
        },
        {
            value: 'Autre',
            label: 'Autre'
        },
    ];

    const rerenderForm = () => {
        setRenderAgain(renderAgain + 1)
    };


    return (
        <>
            <Grid container spacing={1} direction="column" className={classes.form}>
                <Grid className={classes.sectionTitle} item xs={12}>
                    <Typography className={classes.title} component="h3" variant="h5">
                        Lieu du dommage
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography className={classes.text} component="h4" variant="h6">
                        Saisissez une adresse au plus proche du lieu concerné et sélectionnez l’équipement endommagé ou
                        l'équipement le plus proche du lieu de dommage.
                    </Typography>
                </Grid>

                <Carte activeDsp={props.activeDsp} renderFn={rerenderForm}/>

                {values.Ticket !== '0' && values.Ticket !== '1' && values.Ticket &&
                <>
                    <Grid item xs={12}>
                        <Typography className={classes.textConf} component="h4" variant="h6">
                            Un incident a déjà été déclaré sur l'équipement que vous avez choisi.
                        </Typography>
                    </Grid>
                    <Grid className={classes.input} item xs={12}>
                        <SelectField
                            className={classes.innerinput}
                            name={Confirmation.name}
                            label={Confirmation.label}
                            data={confirmationVal}
                        />
                    </Grid>
                </>
                }
                {values.Ticket === '1' &&
                <>
                    <Grid className={classes.input} item xs={12}>
                        <SelectField
                            className={classes.innerinput}
                            name={Structure.name}
                            label={Structure.label}
                            data={equipementVal}
                        />
                    </Grid>
                    {values.Structure === 'Poteau' &&
                    <Grid className={classes.input} item xs={12}>
                        <SelectField
                            className={classes.innerinput}
                            name={Defaut.name}
                            label={Defaut.label}
                            data={dommageVal}
                        />
                    </Grid>
                    }
                </>
                }
                {(values.Confirmation === '1' || values.Ticket === '1') &&
                <Grid className={classes.input} item xs={12}>
                    <InputField
                        name={ComStructure.name}
                        label={ComStructure.label}
                        fullWidth
                        variant="outlined"
                        style={{margin: 'auto'}}
                        margin="normal"
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </Grid>
                }
                {values.Ticket === '1' &&
                <>
                    <Grid item xs={12}>
                        <Typography className={classes.text} component="h4" variant="h6">
                            Pour plus de précision et nous aider dans le traitement, vous pouvez ajouter des photos du
                            dommage. Dans la mesure du possible, merci de nous fournir la plaque d’identification de la
                            structure.
                        </Typography>
                    </Grid>
                    <Grid className={classes.input} item xs={12}>
                        <DropzoneArea
                            acceptedFiles={['image/*']}
                            dropzoneText={"Glissez et déposez une image ou cliquez"}
                            filesLimit={3}
                            showPreviewsInDropzone={false}
                            showPreviews={true}
                            showFileNamesInPreview={true}
                            onChange={(files) => values.Files = files}
                            maxFileSize={5000000}
                        />
                    </Grid>
                </>
                }
            </Grid>
        </>
    );
};

export default Carto;
