import * as Yup from 'yup';
import checkoutFormModel from './checkoutFormModel';

const {
    formField: {
        Danger,
        Geoloc,
        Confirmation,
        X,
        Y,
        Ticket,
        TicketId,
        StatutDeposant,
        QualiteDeposant,
        Structure,
        ComStructure,
        Defaut,
        Nom,
        Prenom,
        Informe,
        Mail,
        Telephone,
        ReferenceEquipement,
        Captcha
    }
} = checkoutFormModel;


export default [
    Yup.object().shape({
        // [Danger.name]: Yup.boolean().required(`${Danger.requiredErrorMsg}`).typeError("Veuillez sélectionner une valeur valide")
    }),
    Yup.object().shape({
        [Geoloc.name]: Yup.boolean().required(`${Geoloc.requiredErrorMsg}`).typeError("Veuillez sélectionner une valeur valide")
    }),
    Yup.object().shape({
        [X.name]: Yup.number().required(`${X.requiredErrorMsg}`).typeError("Veuillez sélectionner une valeur valide"),
        [Y.name]: Yup.number().required(`${Y.requiredErrorMsg}`).typeError("Veuillez sélectionner une valeur valide"),
        [ReferenceEquipement.name]:Yup.string().required(`${ReferenceEquipement.requiredErrorMsg}`).typeError("Veuillez sélectionner une valeur valide"),
        [Ticket.name]: Yup.string().notOneOf(['0'],"Veuillez sélectionner une valeur valide").required(`${Ticket.requiredErrorMsg}`),
        [TicketId.name]: Yup.number().when("Ticket",
            {
                is: (Ticket) => Ticket !== "1",
                then: Yup.number().required(`${TicketId.requiredErrorMsg}`).typeError("Veuillez sélectionner une valeur valide"),
                otherwise: Yup.number().notRequired()
            }),
        [Confirmation.name]: Yup.boolean().when("Ticket",
            {
                is: (Ticket) => Ticket !== "1",
                then: Yup.boolean().required(`${Confirmation.requiredErrorMsg}`).typeError("Veuillez sélectionner une valeur valide"),
                otherwise: Yup.boolean().notRequired()
            }),
        [Structure.name]: Yup.string().when("Ticket",
            {
                is: (Ticket) => Ticket === "1",
                then: Yup.string().required(`${Structure.requiredErrorMsg}`).oneOf([
                    'Local Technique', 'Poteau', 'Coffret sur poteau', 'Armoire sur trottoir', 'Coffret dans chambre souterraine',
                    'Trappe/Plaque au sol', 'Câble souterrain', 'Câble aérien'],"Veuillez sélectionner une valeur valide"),
                otherwise: Yup.string().notRequired()
            }),
        [ComStructure.name]: Yup.string().when(["Confirmation", "Ticket"],
            {
                is: (Confirmation, Ticket) => Confirmation || Ticket === "1",
                then: Yup.string().min(5,'Commentaire trop court').max(255, 'Commentaire trop long').required(`${ComStructure.requiredErrorMsg}`),
                otherwise: Yup.string().min(5,'Commentaire trop court').max(255, 'Commentaire trop long').notRequired()
            }),
        [Defaut.name]: Yup.string()
            .when("Structure",
                {
                    is: (Structure) => Structure === "Poteau",
                    then: Yup.string().required(`${Defaut.requiredErrorMsg}`).oneOf(['Penche', 'Casse', 'Manquant', 'Autre'],"Veuillez sélectionner une valeur valide"),
                    otherwise: Yup.string().notRequired()
                }),
    }),
    Yup.object().shape({
        [StatutDeposant.name]: Yup.string().oneOf(['Auteur', 'Temoin', 'Victime'],"Veuillez sélectionner une valeur valide").required(`${StatutDeposant.requiredErrorMsg}`),
        [QualiteDeposant.name]: Yup.string().oneOf(['Interne', 'Particulier', 'Collectivite', 'Entreprise'],"Veuillez sélectionner une valeur valide").required(`${QualiteDeposant.requiredErrorMsg}`),
        // [Nom.name]: Yup.string().when("StatutDeposant",
        //     {
        //         is: (StatutDeposant) => StatutDeposant !== "Temoin",
        //         then: Yup.string().required(`${Nom.requiredErrorMsg}`).typeError("Veuillez sélectionner une valeur valide"),
        //         otherwise: Yup.string().notRequired()
        //     }),
        [Nom.name]: Yup.string().required(`${Nom.requiredErrorMsg}`),
        [Prenom.name]: Yup.string().notRequired(),
        [Informe.name]: Yup.boolean().when("StatutDeposant",
            {
                is: (StatutDeposant) => StatutDeposant === "Temoin",
                then: Yup.boolean().required(`${Informe.requiredErrorMsg}`).typeError("Veuillez sélectionner une valeur valide"),
                otherwise: Yup.boolean().notRequired()
            }),
        [Mail.name]: Yup.string().email(`${Mail.invalidErrorMsg}`).when(["StatutDeposant", "Informe"],
            {
                is: (StatutDeposant, Informe) => Informe || StatutDeposant !== "Temoin",
                then: Yup.string().required(`${Mail.requiredErrorMsg}`),
                otherwise: Yup.string().notRequired()
            }),
        [Telephone.name]: Yup.string().notRequired(),
    }),
    Yup.object().shape({
        [Captcha.name]: Yup.boolean().required(`${Captcha.requiredErrorMsg}`).typeError("Veuillez sélectionner une valeur valide"),
    })
];
