import React from 'react';
import '../../App.css';
import {
    Grid,
    Typography,
} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import {useFormikContext} from "formik";


const useStyles = makeStyles((theme) => ({
    altiIcon: {
        width: '30%',
        "@media (max-width: 700px)": {
            width: '30% !important'
        }
    },
    altiIconMin: {
        width: '20%'
    },
    iconGridMin: {
        textAlign: 'right',
        marginLeft: '15%',
        fontSize: '0.5em'
    },
}));

const LogoDsp = (props) => {

    const classes = useStyles();

    function _renderLogoDsp(activeDsp){
        switch (activeDsp) {
            case 'Corai':
                return <img alt="Altitude Infrastructure" src="/img/Corai.png"
                            className={classes.altiIcon}/>;
            case 'Dioptic':
                return <img alt="Altitude Infrastructure" src="/img/Dioptic.png"
                            className={classes.altiIcon}/>;
            case 'Doubs':
                return <img alt="Altitude Infrastructure" src="/img/Dioptic.png"
                              className={classes.altiIcon}/>;
            case 'Emeraude':
                return <img alt="Altitude Infrastructure" src="/img/Emeraude.png"
                              className={classes.altiIcon}/>;
            case 'Fibre31':
                return <img alt="Altitude Infrastructure" src="/img/Fibre31.png"
                              className={classes.altiIcon}/>;
            case 'LaFibre06':
                return <img alt="Altitude Infrastructure" src="/img/LaFibre06.png"
                              className={classes.altiIcon}/>;
            case 'LF85':
                return <img alt="Altitude Infrastructure" src="/img/LF85.png"
                            className={classes.altiIcon}/>;
            case 'Losange':
                return <img alt="Altitude Infrastructure" src="/img/Losange.png"
                              className={classes.altiIcon}/>;
            case 'Rosace':
                return <img alt="Altitude Infrastructure" src="/img/Rosace.png"
                              className={classes.altiIcon}/>;
            case 'Manche':
                return <img alt="Altitude Infrastructure" src="/img/Manche.png"
                              className={classes.altiIcon}/>;
            case 'NetGR':
                return <img alt="Altitude Infrastructure" src="/img/NetGR.png"
                              className={classes.altiIcon}/>;
            case 'Octogone':
                return <img alt="Altitude Infrastructure" src="/img/Octogone.png"
                              className={classes.altiIcon}/>;
            case 'PIXL':
                return <img alt="Altitude Infrastructure" src="/img/PIXL.png"
                            className={classes.altiIcon}/>;
            case 'Prisme':
                return <img alt="Altitude Infrastructure" src="/img/Prisme.png"
                            className={classes.altiIcon}/>;
            case 'Resoptic':
                return <img alt="Altitude Infrastructure" src="/img/Resoptic.png"
                              className={classes.altiIcon}/>;
            case 'Rev@':
                return <img alt="Altitude Infrastructure" src="/img/Rev@.png"
                              className={classes.altiIcon}/>;
            case 'THD66':
                return <img alt="Altitude Infrastructure" src="/img/THD66.png"
                              className={classes.altiIcon}/>;
            case 'TPMTHD':
                return <img alt="Altitude Infrastructure" src="/img/TPMTHD.png"
                              className={classes.altiIcon}/>;
            case 'Yconik':
                return <img alt="Altitude Infrastructure" src="/img/Yconik.png"
                            className={classes.altiIcon}/>;
            case 'Vendee':
                return <img alt="Altitude Infrastructure" src="/img/Vendee.png"
                            className={classes.altiIcon}/>;
            case 'GrandNancy':
                return <img alt="Altitude Infrastructure" src="/img/GrandNancy.png"
                            className={classes.altiIcon}/>;
            case 'CoeurCoteFleurie':
                return <img alt="Altitude Infrastructure" src="/img/CoeurCoteFleurie.png"
                            className={classes.altiIcon}/>;
            case 'SommeFibre':
                return <img alt="Altitude Infrastructure" src="/img/SommeFibre.png"
                            className={classes.altiIcon}/>;
            case 'SeineEssonne':
                return <img alt="Altitude Infrastructure" src="/img/SeineEssonne.png"
                            className={classes.altiIcon}/>;
            case 'Oxygene':
                return <img alt="Altitude Infrastructure" src="/img/Oxygene.png"
                            className={classes.altiIcon}/>;
            case 'Sequantic':
                return <img alt="Altitude Infrastructure" src="/img/Sequantic.png"
                            className={classes.altiIcon}/>;
            case 'Syane':
                return <img alt="Altitude Infrastructure" src="/img/Syane.png"
                            className={classes.altiIcon}/>;
            case 'Calvados':
                return <img alt="Altitude Infrastructure" src="/img/Calvados.png"
                            className={classes.altiIcon}/>;
            case 'EuropEssonne':
                return <img alt="Altitude Infrastructure" src="/img/EuropEssonne.png"
                            className={classes.altiIcon}/>;
            case 'Dunkerque':
                return <img alt="Altitude Infrastructure" src="/img/Dunkerque.png"
                            className={classes.altiIcon}/>;
            default:
                return <img alt="Altitude Infrastructure" src="/img/Logo_AI_NEW.png"
                            className={classes.altiIcon}/>;
        }
    }
    return (
        _renderLogoDsp(props.activeDsp)
    )
};

export default LogoDsp;
