import checkoutFormModel from './checkoutFormModel';
const {
    formField: {
        Danger,
        Geoloc,
        X,
        Y,
        Ticket,
        TicketId,
        Confirmation,
        StatutDeposant,
        QualiteDeposant,
        Structure,
        ComStructure,
        Defaut,
        Nom,
        Prenom,
        Informe,
        Mail,
        Telephone,
        ReferenceEquipement,
        Captcha
    }
} = checkoutFormModel;

export default {
    [Danger.name]: '',
    [Geoloc.name]: '',
    [Confirmation.name]: '',
    [X.name]: '',
    [Y.name]: '',
    [Ticket.name]: '',
    [TicketId.name]: '',
    [StatutDeposant.name]: '',
    [QualiteDeposant.name]: '',
    [Structure.name]: '',
    [ComStructure.name]: '',
    [Defaut.name]: '',
    [Nom.name]: '',
    [Prenom.name]: '',
    // [Adresse.name]: '',
    // [CP.name]: '',
    // [Ville.name]: '',
    [Informe.name]: '',
    [Mail.name]: '',
    [Telephone.name]: '',
    [ReferenceEquipement.name]: '',
    [Captcha.name]:''
};
