import React, {useState} from 'react';
import '../../App.css';
import {
    Backdrop, Button, CircularProgress, Fade,
    Grid,
    Typography,
} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import {useFormikContext} from "formik";
import moment from "moment";
import {postAddVieTicket, postCreateTicket} from "../../ressources/services/irris";
import {postDeclaration} from "../../ressources/services/declaration";
import Resume from "../Resume";


const useStyles = makeStyles((theme) => ({
    title: {
        textAlign: 'center',
        margin: '0.25em',
        fontSize: '1.75em',
        fontWeight: 'bold'
    },
    input: {
        padding: '1em !important'
    },
    form: {
        width: '80%',
        margin: 'auto',
        borderRadius: '1em',
        boxShadow: '1px 1px 12px #555'
    },
    sectionTitle: {
        backgroundColor: '#EF9700',
        borderRadius: '1em 1em 0 0',
        color: 'white',
        width: '100%'
    },
    text: {
        textAlign: 'justify',
        fontSize: '0.90em',
        margin: '1em'
    },
    innerinput: {
        width: '100%'
    },
    buttonField: {
        margin: '2em 0 0 0',
        padding: '0.5em !important',
        width: '100%'
    },
    button: {
        backgroundColor: '#003B5E',
        color: 'white',
        fontWeight: 'bold'
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
    },
}));

const Erreur = (props) => {

    const classes = useStyles();
    const {values, submitForm} = useFormikContext();

    const [loading, setLoading] = useState(false);
    const [erreur, setErreur] = useState(false);
    const [activeStep, setActiveStep] = useState(0);

    function _submitForm() {
        setLoading(true)
        if (props.erreur === 1) {
            _createTicket()
        } else if (props.erreur === 2) {
            _addVieTicket()
        } else if (props.erreur === 3) {
            _saveTicket()
        }
    }

    async function _createTicket() {
        let infosTicket = {};

        let symptomeTicket = values.StatutDeposant + ' - ' + values.QualiteDeposant + '\n' + values.ReferenceEquipement + ' -  Signalé comme : ' + values.Structure;
        if (values.Structure === 'Poteau') {
            symptomeTicket += '\nDefaut signalé : ' + values.Defaut;
        }
        symptomeTicket += '\nCommentaire : ' + values.ComStructure;

        infosTicket = {
             idDemandeur: 915, // PROD
            // idDemandeur: 799, // DEV
            troubleDescription: symptomeTicket,
            troubleDetectionTime: moment(Date()).format(),
            idEmplacement: values.ReferenceEquipement,
            defectClassId: 1001,
            categorieTicket: 4,
            GRTDelay: 240,
            GRTSignalingRangeDay: "5J/7",
            GRTSignalingRangeHour: "24h/24",
            GRTRecoveryRange: "SIGNALISATION",
        };

        const fichiers = [];
        const token = 'token';

        postCreateTicket(infosTicket, "QUEUED", fichiers, token).then(response => {
            values.Ticket = response.data.troubleTicketKey;
            _saveTicket()
        }).catch(function (error) {
            setLoading(false);
            setErreur(true);
        });
    }

    async function _addVieTicket() {
        const idTroubleTicket = values.TicketId;
        const messageContent = values.ComStructure;
        const niveauConfidentialite = "PUBLIC";
        const token = 'token'

        postAddVieTicket(idTroubleTicket, messageContent, niveauConfidentialite, token).then(response => {
            _saveTicket()
        }).catch(function (error) {
            setLoading(false);
            setErreur(true);
        });
    }

    async function _saveTicket() {
        postDeclaration(values).then(response => {
            setLoading(false)
            setActiveStep(5)
        }).catch(function (error) {
            setLoading(false);
            setErreur(true);
        });
    }

    if(activeStep !==5){
    return (
        <Grid container spacing={1} direction="column" className={classes.form} alignItems="center">
            <Fade in={loading}>
                <Backdrop className={classes.backdrop} open={true}>
                    <CircularProgress color="primary"/>
                </Backdrop>
            </Fade>
            <Grid className={classes.sectionTitle} item xs={12}>
                <Typography className={classes.title} component="h3" variant="h5">
                    Oups ! Une erreur est survenue
                </Typography>
            </Grid>
            {erreur === false &&
            <>
                <Grid item xs={12}>
                    Veuillez appuyer sur le bouton ci-dessous afin de relancer l'enregristrement de votre déclaration.
                </Grid>
                <Grid item xs={4}>
                    <Button className={classes.button} fullWidth
                            variant="contained" onClick={_submitForm}
                    ><i className={classes.button}/> Valider
                    </Button>
                </Grid>
            </>
            }
            {erreur === true &&
            <>
                <Grid item xs={12}>
                   Votre déclaration n'a pas été enregistrée, veuillez contacter Altitude Infra par téléphone.
                </Grid>
            </>
            }
        </Grid>
    );
    }else if (activeStep === 5){
        return <Resume/>
    }
};

export default Erreur;
