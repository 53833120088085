import React from 'react';
import '../../App.css';
import {
    Grid,
    Typography,
} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';


const useStyles = makeStyles((theme) => ({
    title: {
        textAlign: 'center',
        margin: '0.25em',
        fontSize: '1.75em',
        fontWeight: 'bold'
    },
    input: {
        padding: '1em !important'
    },
    form: {
        width: '80%',
        margin: 'auto',
        borderRadius: '1em',
        boxShadow: '1px 1px 12px #555'
    },
    sectionTitle: {
        backgroundColor: '#EF9700',
        borderRadius: '1em 1em 0 0',
        color: 'white',
        width: '100%'
    },
    text: {
        textAlign: 'justify',
        fontSize: '0.90em',
        margin: '1em'
    },
    innerinput: {
        width: '100%'
    },
}));

const Remerciement = () => {

    const classes = useStyles();

    return (
        <Grid container spacing={1} direction="column" className={classes.form} alignItems="center">
            <Grid className={classes.sectionTitle} item xs={12}>
                <Typography className={classes.title} component="h3" variant="h5">
                    Signalement annulé
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography className={classes.text} component="h4" variant="h6">
                    Merci d'avoir utilisé l'application de déclaration de dommages réseaux
                </Typography>
            </Grid>
        </Grid>
    );
};

export default Remerciement;
